import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslate } from '../config/translateContext';
import { useAuth } from '../config/AuthContext';
import Dropdown from 'react-bootstrap/Dropdown';
import axiosInstance from '../config/axiosConfig';









function MyNavbar() {
    const { isTranslated, toggleTranslate } = useTranslate();
    const { isAuthenticated, logout, user, hasLebenslauf, downloadUrl } = useAuth(); // Dodaj hasLebenslauf i downloadUrl
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const { slug } = useParams();
    const [newMessage, setNewMessage] = useState(false); // State for tracking new messages
    const [newThread, setNewThread] = useState(false);  // Flaga dla nowego wątku

    const [threadsData, setThreadsData] = useState([]);
    const [commentsData, setCommentsData] = useState([]);
    const [newComment, setNewComment] = useState(false);
    const [notificationsData, setNotificationsData] = useState([]);


// Aktualizacja notificationsData
useEffect(() => {
    setNotificationsData(prevData => {
        return [
            ...threadsData.map(thread => ({ type: 'thread', title: thread.title, land_slug: thread.land_slug })),
            ...commentsData.map(comment => ({ type: 'comment', comment_content: comment.comment_content, thread_title: comment.thread_title, post_id: comment.post_id })),

        ];
    });
}, [threadsData, commentsData]);  // Zależność od threadsData i commentsData


//fetch not
useEffect(() => {
    const fetchNotifications = async () => {
        if (isAuthenticated) {
            try {
                const response = await axiosInstance.get('/chats/api/notifications/options');

                console.log("Otrzymane dane z API:", response.data);  // Logowanie danych

                setNotificationsData(prevData => {
                    const loadedNotifications = response.data.map(notification => {
                        if (notification.notification_type === 'thread') {
                            return {
                                type: 'thread',
                                title: notification.thread_title,
                                land_slug: notification.land_slug,
                            };
                        } else if (notification.notification_type === 'comment') {
                            return {
                                type: 'comment',
                                comment_content: notification.comment_content,
                                thread_title: notification.thread_title_for_comment,
                                post_id: notification.post_id,
                            };
                        } else if (notification.notification_type === 'publish') {
                            return {
                                type: 'publish',
                                title: notification.publish_title,
                                post_id: notification.publish_post_id,
                            };
                        } else if (notification.notification_type === 'application') {
                            return {
                                type: 'application',
                                message: notification.application_message,
                                work_advertisement_id: notification.work_advertisement_id,
                            };
                        }
                        return null;  // W przypadku innego typu, ignorujemy
                    }).filter(item => item !== null);  // Usuwamy potencjalne wartości null

                    return [...prevData, ...loadedNotifications];
                });
            } catch (error) {
                console.error('Błąd podczas pobierania notyfikacji:', error);
            }
        }
    };

    fetchNotifications();
}, [isAuthenticated]);













// notification
useEffect(() => {
    if (isAuthenticated) {
        const token = localStorage.getItem('access_token');
        const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        const wsBaseURL = 'obrotni.info';
        const wsURL = `${wsProtocol}${wsBaseURL}/ws/notifications/?token=${encodeURIComponent(token)}`;

        console.log("WebSocket URL:", wsURL);

        const ws = new WebSocket(wsURL);

        ws.onopen = () => {
            console.log('WebSocket connection opened');
        };

        ws.onmessage = (event) => {
            console.log("WebSocket message received:", event.data);
            try {
                const data = JSON.parse(event.data);
                console.log("Parsed WebSocket message:", data);  // Loguj parsowane dane
                if (data.new_message) {
                    setNewMessage(true);  // Ustaw flagę przy nowej wiadomości
                    console.log("New message flag set to true");
                }
            } catch (error) {
                console.error("Error parsing WebSocket message:", error);
            }
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onclose = () => {
            console.log("WebSocket connection closed");
        };

        return () => {
            ws.close();
            console.log("WebSocket connection closed on component unmount");
        };
    }
}, [isAuthenticated]);

//forum
useEffect(() => {
    if (isAuthenticated) {
        const token = localStorage.getItem('access_token');
        const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        const wsBaseURL = 'obrotni.info';  // Zmień na odpowiednią domenę
        const wsURL = `${wsProtocol}${wsBaseURL}/ws/notifications/forum/?token=${encodeURIComponent(token)}`;

        console.log("Connecting to WebSocket Forum URL:", wsURL);

        const ws = new WebSocket(wsURL);

        ws.onopen = () => {
            console.log("WebSocket connected to forum");
        };

        ws.onmessage = (event) => {
            console.log("WebSocket message received:", event.data);
            const data = JSON.parse(event.data);

            if (data.new_thread) {
                console.log("New thread detected:", data);
                setNewThread(true);

                setThreadsData(prevData => {
                    // Sprawdzanie, czy wątek już istnieje, aby uniknąć duplikatów
                    if (prevData.some(thread => thread.land_slug === data.land_slug && thread.title === data.thread_title)) {
                        return prevData;
                    }
                    return [
                        { type: 'thread', title: data.thread_title, land_slug: data.land_slug },
                        ...prevData  // Nowy wątek dodany na górze
                    ];
                });
            }
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onclose = () => {
            console.log("WebSocket disconnected");
        };

        return () => {
            ws.close();  // Zamknięcie WebSocket przy unmount
        };
    }
}, [isAuthenticated]);






//komentarzenie
useEffect(() => {
    if (isAuthenticated) {
        const token = localStorage.getItem('access_token');
        const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        const wsBaseURL = 'obrotni.info';  // Zmień na odpowiednią domenę
        const wsURL = `${wsProtocol}${wsBaseURL}/ws/notifications/comments/?token=${encodeURIComponent(token)}`;

        console.log("Connecting to comments WebSocket URL:", wsURL);

        const ws = new WebSocket(wsURL);

        ws.onopen = () => {
            console.log("WebSocket connected to comments");
        };

        ws.onmessage = (event) => {
            console.log("WebSocket message received:", event.data);
            const data = JSON.parse(event.data);

            if (data.new_comment) {
                console.log("New comment detected:", data);
                setNewComment(true);
                setCommentsData(prevData => {
                    if (prevData.some(comment => comment.post_id === data.post_id && comment.comment_content === data.comment_content)) {
                        return prevData;
                    }
                    return [
                        {
                            comment_content: data.comment_content,
                            thread_title: data.thread_title,
                            post_id: data.post_id
                        },
                        ...prevData  // Nowy komentarz dodany na górze
                    ];
                });
            }
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onclose = () => {
            console.log("WebSocket disconnected");
        };

        return () => {
            ws.close();  // Zamknięcie WebSocket przy unmount
        };
    }
}, [isAuthenticated]);




//published
useEffect(() => {
    if (isAuthenticated) {
        const token = localStorage.getItem('access_token');
        const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        const wsBaseURL = 'obrotni.info';  // Zmień na odpowiednią domenę
        const wsURL = `${wsProtocol}${wsBaseURL}/ws/notifications/publish/?token=${encodeURIComponent(token)}`;

        console.log("WebSocket URL:", wsURL);  // Zaloguj pełny adres URL WebSocket

        const ws = new WebSocket(wsURL);

        ws.onopen = () => {
            console.log("WebSocket connected for publish notifications");
        };

        ws.onmessage = (event) => {
            console.log("WebSocket message received:", event.data);
            const data = JSON.parse(event.data);

            if (data.new_publish) {
                // Dodaj nowe powiadomienie o publikacji do stanu
                setNotificationsData(prevData => {
                    if (prevData.some(notification => notification.post_id === data.post_id && notification.type === 'publish')) {
                        return prevData;
                    }
                    return [
                        { type: 'publish', title: data.post_title, post_id: data.post_id },
                        ...prevData  // Nowa publikacja dodana na górze
                    ];
                });
            }
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onclose = () => {
            console.log("WebSocket disconnected for publish notifications");
        };

        return () => {
            ws.close();  // Zamknięcie WebSocket przy unmount
        };
    }
}, [isAuthenticated]);




useEffect(() => {
    if (isAuthenticated) {
        const token = localStorage.getItem('access_token');
        const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        const wsBaseURL = 'obrotni.info';  // Zamień na swoją domenę
        const wsURL = `${wsProtocol}${wsBaseURL}/ws/notifications/applications/?token=${encodeURIComponent(token)}`;

        const ws = new WebSocket(wsURL);

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.new_application) {
                setNotificationsData(prevData => {
                    if (prevData.some(notification => notification.work_advertisement_id === data.work_advertisement_id && notification.type === 'application')) {
                        return prevData;
                    }
                    return [
                        { type: 'application', message: data.message, work_advertisement_id: data.work_advertisement_id },
                        ...prevData  // Nowa aplikacja dodana na górze
                    ];
                });
            }
        };

        return () => ws.close();
    }
}, [isAuthenticated]);








    const handleProfileClick = () => {
        const profileSlug = user?.profile_slug || user?.slug;
        console.log('Profile slug on click:', profileSlug);
        if (profileSlug) {
            navigate(`/profile/update/${profileSlug}`);
        } else {
            console.log('No profile slug available');
        }
    };

    const handleMessageClick = () => {
        const profileSlug = user?.profile_slug || user?.slug;
        console.log('Profile slug on click:', profileSlug);

        if (profileSlug) {
            navigate(`/profile/${profileSlug}/contact`);
            setNewMessage(false);
        } else {
            console.log('No profile slug available');
        }
    };


    const handleDownloadClick = () => {
        if (downloadUrl) {


            window.open(downloadUrl, '_blank');
        } else {
            console.log('Download URL is not available');
        }
    };
const handleCommentClick = (postId) => {
    setNotificationsData(prevData => prevData.filter(notification => notification.post_id !== postId || notification.type !== 'comment'));

    // Wysyłamy żądanie DELETE do serwera bez użycia pk
    axiosInstance.delete('/chats/api/notifications/options/', {
        data: { type: 'comment', post_id: postId }  // Wysyłamy dane w ciele zapytania
    })
    .then(response => {
        console.log('Powiadomienie o komentarzu zostało usunięte.');
    })
    .catch(error => {
        console.error('Błąd podczas usuwania powiadomienia o komentarzu:', error);
    });

    setNewComment(false);
};

const handlePublishClick = (postId) => {
    // Usuwanie powiadomienia o publikacji z lokalnej listy
    setNotificationsData(prevData => prevData.filter(notification => notification.post_id !== postId || notification.type !== 'publish'));

    // Wysyłamy żądanie DELETE do serwera bez użycia pk
    axiosInstance.delete('/chats/api/notifications/options/', {
        data: { type: 'publish', post_id: postId }  // Wysyłamy dane w ciele zapytania
    })
    .then(response => {
        console.log('Powiadomienie o publikacji zostało usunięte.');
    })
    .catch(error => {
        console.error('Błąd podczas usuwania powiadomienia o publikacji:', error);
    });
};


const handleThreadClick = (landSlug) => {
    // Usuwanie powiadomienia o nowym wątku z listy lokalnej
    setNotificationsData(prevData => prevData.filter(notification => notification.land_slug !== landSlug || notification.type !== 'thread'));

    // Wysyłamy żądanie DELETE do serwera bez użycia pk
    axiosInstance.delete('/chats/api/notifications/options/', {
        data: { type: 'thread', land_slug: landSlug }  // Wysyłamy dane w ciele zapytania
    })
    .then(response => {
        console.log('Powiadomienie o wątku zostało usunięte.');
    })
    .catch(error => {
        console.error('Błąd podczas usuwania powiadomienia o wątku:', error);
    });

    // Nawigacja do wybranego wątku
    setNewThread(false);
    navigate(`/landy/${landSlug}`);
    console.log('Navigating to land:', landSlug);
};



const handleAppClick = (workAdvertisementId) => {
    // Usuwanie powiadomienia o aplikacji z listy lokalnej
    setNotificationsData(prevData => prevData.filter(notification => notification.work_advertisement_id !== workAdvertisementId || notification.type !== 'application'));

    // Wysyłamy żądanie DELETE do serwera bez użycia pk
    axiosInstance.delete('/chats/api/notifications/options/', {
        data: { type: 'application', work_advertisement_id: workAdvertisementId }  // Wysyłamy dane w ciele zapytania
    })
    .then(response => {
        console.log('Powiadomienie o aplikacji zostało usunięte.');
    })
    .catch(error => {
        console.error('Błąd podczas usuwania powiadomienia o aplikacji:', error);
    });

    console.log('Application notification clicked:', workAdvertisementId);
};



    const mainLinks = [
        { id: "obrotni", path: "/project", name: "Obrotni", nameDe: "Obrotni", className: 'text-body' },
        { id: "usługi", path: "/landy", name: "Usługi", nameDe: "Dienstleistungen", className: 'text-danger' },
        { id: "infoteka", path: "/infoteka", name: "Infoteka", nameDe: "Infotheke", className: 'text-warning' },
        { id: "praca", path: "/praca", name: "Praca", nameDe: "Arbeit", className: 'orange' },
    ];

    const userLinksIsAuth = [
        {
            id: "profile",
            name: <i className="bi bi-gear"></i>,
            nameDe: <i className="bi bi-gear"></i>,
            className: 'purple',
            onClick: handleProfileClick,
            style: { cursor: 'pointer' }
        },
        {
            id: "messages",
            name: <i className={`bi bi-chat ${newMessage ? 'text-success' : ''}`}></i>,
            nameDe: <i className={`bi bi-chat ${newMessage ? 'text-success' : ''}`}></i>,
            className: 'purple',
            onClick: handleMessageClick,
            style: { cursor: 'pointer' }
        },
 {
    id: "notifications",
    name: (
        <Dropdown drop="start">
            {notificationsData.length > 0 ? (
                <>
                    <Dropdown.Toggle
                        variant="btn"
                        id="dropdown-basic"
                        className="dropstart"
                    >
                        <i className="bi bi-exclamation-lg text-success"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {notificationsData.map((notification, index) => (
                            <Dropdown.Item
                                key={index}
                                onClick={() => {
                                    if (notification.type === "thread") {
                                        handleThreadClick(notification.land_slug);
                                    } else if (notification.type === "comment") {
                                        handleCommentClick(notification.post_id);
                                    } else if (notification.type === "publish") {
                                        handlePublishClick(notification.post_id);
                                    } else if (notification.type === "application") {
                                        handleAppClick(notification.work_advertisement_id);
                                    }
                                }}
                            >
                                {notification.type === "thread" && `Neuer Thread: ${notification.title}`}
                                {notification.type === "comment" && `Neuer Kommentar: ${notification.comment_content} im ${notification.thread_title}`}
                                {notification.type === "publish" && `Dein Artikel "${notification.title}" wurde veröffentlicht`}
                                {notification.type === "application" && `Neue Bewerbung: ${notification.message}`}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </>
            ) : (
                <i className="bi bi-exclamation-lg"></i>
            )}
        </Dropdown>
    )
},







        {
            id: "sources",
            name: <i className="bi bi-file-arrow-down-fill"></i>,
            nameDe: <i className="bi bi-file-arrow-down-fill"></i>,
            className: 'purple',
            onClick: handleDownloadClick,
            style: { cursor: 'pointer' }
        },
        {
            id: "logout",
            path: "/logout",
            name: "Wyloguj się",
            nameDe: "Abmelden",
            className: 'purple',
            onClick: () => {
                logout();
                navigate('/login');
            }
        },
    ];


    const userLinksNotAuth = [
        { id: "login", path: "/login", name: "Zaloguj się", nameDe: "Anmelden", className: 'purple' },
        { id: "register", path: "/register", name: "Zarejestruj się", nameDe: "Registrieren", className: 'purple' },
    ];
const renderLink = useCallback((link) => {
    if (["profile", "messages", "notifications"].includes(link.id)) {
        return (
            <Nav.Link
                key={link.id}
                as="span"
                className={link.className}
                onClick={() => {
                    setExpanded(false);
                    link.onClick && link.onClick();  // Upewnij się, że wywołuje odpowiedni onClick
                }}
            >
                {link.id === "notifications" ? link.name : (isTranslated ? link.nameDe : link.name)}
            </Nav.Link>
        );
    }

    return (
        <Nav.Link
            key={link.id}
            as={Link}
            to={link.path}
            className={link.className}
            onClick={() => setExpanded(false)}
        >
            {isTranslated ? link.nameDe : link.name}
        </Nav.Link>
    );
}, [isTranslated]);



    return (
        <>
            <Navbar expand="lg" className="bg-white position-absolute w-100 top-0 mt-0 pt-0" expanded={expanded} >
                <Container fluid className='pt-3'>
                    <Navbar.Brand as={Link} to="/">
                        <img src='/img/logo.png' alt="logo" className='img-fluid logo' />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {mainLinks.map(renderLink)}
                        </Nav>
                        <Nav>
                            <button className="btn gradient-text" onClick={toggleTranslate}>
                                {isTranslated ? 'Przetłumacz na polski' : 'Übersetzen der Seitenstruktur ins Deutsch'}
                            </button>
                            {isAuthenticated ? userLinksIsAuth.map(renderLink) : userLinksNotAuth.map(renderLink)}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default MyNavbar;
