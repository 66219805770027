import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Intro from './sites/intro';
import MyNavbar from './components/navBar';
import Footer from './components/footer';
import Artykul from './components/artykul';
import InfotekaList from './sites/infoteka-list';
import CategoryPress from './sites/categoryPress';
// register
import Registration from './sites/register';
import successSite from './sites/success';
// dokumenty
import { LoadingProvider } from './config/loadingContext';
import Loader from './components/Loader';
// translate
import { TranslateProvider } from './config/translateContext';
import Impressum from './sites/impressum';
import Daten from './sites/daten';
import Agd from './sites/agd';
import Login from './sites/login';
import UpdateProfileView from './sites/updateProfile';
import WorkList from './sites/workList';
import Profile from './sites/profile';
import SubscriptionDetail from './sites/SubscriptionDetail';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentSuccess from './sites/paymentSuccess';
import AuthContext, { AuthProvider } from './config/AuthContext';
import CreateWork from './sites/createWork';
import CreateLebenslauf from './sites/createLebens';
import LebenslaufDetail from './sites/LebenslaufDetail';
import LebenslaufDeDetail from './sites/lebenslaufDe';
import { Logout } from './sites/logout';
import AnzeigSucces from './sites/workAnzeigSuccess';
import PostCreate from './sites/createPost';
import Main from './sites/main';
import PostSuccess from './sites/postSuccess';
import WorkAdvertisementDetail from './sites/workAdv';
import Bewerbung from './sites/bewerbungSucces';
import Lands from './sites/lands';
import LandDetail from './sites/landDetail';
import CityDetail from './sites/cityDetail'
import Contacts from './sites/contact';
import PasswordResetRequest from './sites/passwordReset';
import PasswordResetConfirm from './sites/passwordResetConfirm';
const stripePromise = loadStripe('pk_live_51PSwQGP0Uqj7v8hTCMUmy3DxoIhgpUOzPjB2w51RzNr5TpPaPhpuV4usVJX39Vpe7kZFhjFxlHyuHPjB9oWyeFeb00XwKc6gaW');


function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <LoadingProvider>
          <TranslateProvider>
            <div className='main'>
              <Loader />
              <MyNavbar />
    
     
           <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/project" element={<Intro />} />
                <Route path='/infoteka/' element={<InfotekaList />} />
                <Route path='/infoteka/dzialy/:slug' element={<CategoryPress />} />
                <Route path="/infoteka/:slug" element={<Artykul />} />
                <Route path="/impressum/:slug" element={<Impressum />} />
                <Route path="/praca/" element={<WorkList />} />
                <Route path="/praca/:pk" element={<WorkAdvertisementDetail />} />
                <Route path="/praca/bewerbung/success" element={<Bewerbung />} />
                <Route path="/register" element={<Registration />} />
      
                <Route path="/agd" element={<Agd />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/pass-reset" element={<PasswordResetRequest />} />
                <Route path="/password/reset/confirm/:uid/:token/" element={<PasswordResetConfirm />} />

                <Route path="/daten" element={<Daten />} />
                <Route path="/profile/:slug" element={<Profile />} />
                <Route path="/profile/:slug/contact" element={<Contacts />} />
                <Route path='/profile/update/:slug' element={<UpdateProfileView />} />
                <Route path="/subscription/:id" element={
                  <Elements stripe={stripePromise}>
                    <SubscriptionDetail />
                  </Elements>
                } />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/create-ad" element={<CreateWork />} />
                <Route path="/create-cv" element={<CreateLebenslauf />} />
                <Route path="/cv/:id" element={<LebenslaufDetail />} />
                <Route path="/cv/de/:id" element={<LebenslaufDeDetail />} />
                <Route path="/post/create" element={<PostCreate />} />
                <Route path="/post/success" element={<PostSuccess />} />
                <Route path="/praca/adv/success" element={<AnzeigSucces/>}/>
                <Route path='/landy/' element={<Lands/>}/>
                <Route path='/landy/:slug' element={<LandDetail/>}/>
                <Route path="/city/:slug" element={<CityDetail />} />


              </Routes>
  
            
                <Footer />
        
            </div>
            <div className="background">
                    <div className="shape circle"></div>
                    <div className="shape square"></div>
                    <div className="shape triangle"></div>
                </div>
          </TranslateProvider>
        </LoadingProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;