import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; 
import { useTranslate } from '../config/translateContext';
import { useAuth } from '../config/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../config/loadingContext';
import axiosInstance from '../config/axiosConfig';



function CreateWork() {
    const { isTranslated } = useTranslate();
    const { isAuthenticated, user } = useAuth();
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [lands, setLands] = useState([]);
    const [time, setTime] = useState([]);
    const [jobsCategories, setJobsCategories] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [cities, setCities] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const loading = useLoading();
    const fileInputRef = useRef(null);
    const [formData, setFormData] = useState({
        autor: user ? user.id : '',
        land_id: '',
        city_id: '',
        category_id: '',
        time_id: '',
        job_id: '',
        accomodation: false,
        umowa_niemiecka: false,
        status: true,
        obrotni_job: false,
        name_pl: '',
        description_pl: '',
        wynagrodzenie_pl: '',
        name_de: '',
        description_de: '',
        wynagrodzenie_de: '',
        img: null,
        is_de: false
    });

    useEffect(() => {
        const fetchLands = async () => {
            try {
                const response = await axiosInstance.get(`/landy/`);
                setLands(response.data);
            } catch (error) {
                console.error('Error fetching lands:', error);
            }
        };
        fetchLands();
    }, []);

    const handleLandChange = async (e) => {
        const { value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            land_id: value,
            city_id: '' // Reset city when land changes
        }));
    
        const token = localStorage.getItem('access_token');
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
    
        try {
            console.log(`Fetching cities for land ID: ${value}`);
            const response = await axiosInstance.get(`/ad/create/?land=${value}`, config);
            console.log('Cities response:', response.data);
            setCities(response.data.cities || []);
        } catch (error) {
            console.error('Error fetching cities:', error.response ? error.response.data : error.message);
        }
    };
    
    
    const handleCityChange = async (e) => {
        const { value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            city_id: value
        }));
    
        const token = localStorage.getItem('access_token');
        if (!token) {
            console.error('No access token found');
            return;
        }
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
    
        try {
            console.log(`Fetching categories for city ID: ${value}`);
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ad/create/?city=${value}`, config);
            console.log('Categories response:', response.data);
    
            // Sprawdź, czy kategorie mają właściwość name_de
            response.data.categories.forEach(category => {
                console.log(`Category: ${category.id}, Name DE: ${category.name_de}`);
            });
    
            setJobsCategories(response.data.categories || []);
        } catch (error) {
            console.error('Error fetching categories:', error.response ? error.response.data : error.message);
        }
    };
    
    const handleCategoryChange = async (e) => {
        const { value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            category_id: value,
            job_id: '' // Reset job when category changes
        }));
    
        const token = localStorage.getItem('access_token');
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ad/create/?category=${value}`, config);
            console.log('Jobs response:', response.data.jobs); // Dodaj logowanie odpowiedzi
            setJobs(response.data.jobs || []);
        } catch (error) {
            console.error('Error fetching jobs:', error.response ? error.response.data : error.message);
        }
    };
    
    
    
    useEffect(() => {
        const fetchTimeWork = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/timeworks/`);
                setTime(response.data);
            } catch (error) {
                console.error('Error fetching timeworks:', error);
            }
        };
        fetchTimeWork();
    }, []);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/jobs/`);
                setJobs(response.data);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };
        fetchJobs();
    }, []);
    

const handleLanguageSelect = (e) => {
     setFormData((prevData) => ({
            ...prevData,
            is_de: selectedLang === 'DE' ? true : false
        }));
    const selectedLang = e.target.name;
    setSelectedLanguage(selectedLang);

    // Jeśli język to "DE", ustawiamy is_de na true, w przeciwnym razie nic nie robimy

};


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: checked
        }));
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setFormData((prevFormData) => ({
            ...prevFormData,
            img: e.target.files[0]  // Upewnij się, że img jest w formData
        }));
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
const handleRefresh = () => {
window.location.reload();
};
    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/ad/create/`;

        const token = localStorage.getItem('access_token');
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        };

        const data = new FormData();
        for (const key in formData) {
            if (formData[key] !== null && formData[key] !== '') {
                data.append(key, formData[key]);
            }
        }

        if (selectedFile) {
            data.append('img', selectedFile);  // Dodanie img do formData
        }

        try {
            const response = await axios.post(apiUrl, data, config);
            if (response.status === 201) {
                // Handle successful response
                console.log('Ogłoszenie utworzone:', response.data);
            } else {
                console.error('Nie udało się utworzyć ogłoszenia.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            navigate('/praca/adv/success');
        }
    };
    
    if (!isAuthenticated) {
        return <div className='vh10 container d-flex justify-content-center cloude w-100 h-100'>
            <h2>{isTranslated ? 'Bitte einloggen, um eine Anzeige zu erstellen.' : 'Proszę się zalogować, aby utworzyć ogłoszenie.'}</h2>
        </div>;
    }

    return (
        <div className='container vh30 create-add'>
            {!selectedLanguage ? (
                <div className='row gap-2 justify-content-center'>
                    <h2 className='mb-5 ms-5'>{isTranslated ? 'In welcher Sprache möchten Sie die Anzeige hinzufügen?' : 'W jakim języku chcesz dodać ogłoszenie?'}</h2>
                
                     <div className='col-md-6 col-12 col-sm-12 d-flex justify-content-center align-items-center'>
                     <div className='d-block'>
                        <button className="btn btn-outline-primary my-4" name="DE" onClick={handleLanguageSelect}>
                        Deutsche Sprache
                     </button>
                     <p>Erstellen Sie eine Anzeige "Arbeit anbieten".</p>
                        <p>Die künstliche Intelligenz wird den Anzeigentext ins Polnische übersetzen. Die Originalversion wird auch für Interessierte verfügbar sein. Viel Erfolg bei der Mitarbeitersuche!</p>
                      
                    </div>
                    </div>
                    <div className='col-md-6 col-12 col-sm-12 d-flex justify-content-center align-items-center'>
                       <div className='d-block'>
                       <button className="btn btn-outline-primary my-4" name="PL" onClick={handleLanguageSelect}>
                          Język polski
                        </button>
                        <p>Życzymy powodzenia w poszukiwaniu pracowników</p>
                        <p>Cieszymy się, że możemy pomóc!</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {selectedLanguage === 'PL' && (
                        <div className='vh30'>
                            <button className='btn btn-link' onClick={handleRefresh}>Wróć</button>
                            <h2>{isTranslated ? 'Erstellen Sie ein polnisches Stellenangebot' : 'Stwórz polskie ogłoszenie o pracę'}</h2>
                            <form onSubmit={handleSubmit}>
                                <div className='row d-flex justify-content-center my-5'>
                                    <div className='col-lg-4 ps-4 py-0 pe-0 d-flex bg-light justify-content-end'>
                                        <div className='d-block position-relative'
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleFileInputClick}
                                            style={{ cursor: 'pointer' }}>
                                            <img src='logo/logo.png' className='w-100 bg-white' />
                                            {isHovered && (
                                                <div className='lebens-overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center' style={{ top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.5)', color: 'white' }}>
                                                    <h2>Zmień</h2>
                                                </div>
                                            )}
                                        </div>
                                        <input name='img' type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="name_pl" className="form-label">{isTranslated ? 'Name der Anzeige' : 'Nazwa ogłoszenia'}</label>
                                    <input type="text" className="form-control" id="name_pl" name="name_pl" value={formData.name_pl} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description_pl" className="form-label">{isTranslated ? 'Beschreibung der Anzeige' : 'Opis ogłoszenia'}</label>
                                    <textarea className="form-control" id="description_pl" name="description_pl" value={formData.description_pl} onChange={handleChange} required></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="wynagrodzenie_pl" className="form-label">{isTranslated ? 'Vergütung' : 'Wynagrodzenie'}</label>
                                    <input type="text" className="form-control" id="wynagrodzenie_pl" name="wynagrodzenie_pl" value={formData.wynagrodzenie_pl} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="land" className="form-label">{isTranslated ? 'Bundesland' : 'Land'}</label>
                                    <select className="form-control" id="land" name="land_id" value={formData.land_id} onChange={handleLandChange} required>
                                        <option value="">{isTranslated ? 'Wählen Sie ein Land' : 'Wybierz land'}</option>
                                        {lands.map((land) => (
                                            <option key={land.id} value={land.id}>{land.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="city" className="form-label">{isTranslated ? 'Stadt' : 'Miasto'}</label>
                                    <select className="form-control" id="city_id" name="city_id" value={formData.city_id} onChange={handleCityChange} required>
                                        <option value="">{isTranslated ? 'Wählen Sie eine Stadt' : 'Wybierz miasto'}</option>
                                        {cities.map((city) => (
                                            <option key={city.id} value={city.id}>{city.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="category" className="form-label">{isTranslated ? 'Kategorie' : 'Kategoria'}</label>
                                    <select className="form-control" id="category" name="category_id" value={formData.category_id} onChange={handleCategoryChange} required>
    <option value="">{isTranslated ? 'Wählen Sie eine Kategorie' : 'Wybierz kategorie'}</option>
    {jobsCategories.map((category) => (
        <option key={category.id} value={category.id}>{category.name_de}</option>
    ))}
</select>


                                </div>
         
                                <div className="mb-3">
                                    <label htmlFor="job" className="form-label">{isTranslated ? 'Arbeitsstelle' : 'Stanowisko'}</label>
                                    <select className="form-control" id="job" name="job_id" value={formData.job_id} onChange={handleChange} required>
    <option value="">Wählen Sie eine position</option>
    {jobs.map((job) => (
        <option key={job.id} value={job.id}>{job.name_de}</option>
    ))}
</select>


                                </div>
                                <div className="mb-3">
                                    <label htmlFor="time" className="form-label">{isTranslated ? 'Arbeitszeit' : 'Wymiar czasu pracy'}</label>
                                    <select className="form-control" id="time" name="time_id" value={formData.time_id} onChange={handleChange} required>
                                        <option value="">{isTranslated ? 'Wählen Sie eine Arbeitzeitregelung' : 'Wymiar czasowy'}</option>
                                        {time.map((time) => (
                                            <option key={time.id} value={time.id}>{time.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="accomodation" name="accomodation" checked={formData.accomodation} onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="accomodation">{isTranslated ? 'Unterkunft vorhanden' : 'Czy zapewnione zakwaterowanie'}</label>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="umowa_niemiecka" name="umowa_niemiecka" checked={formData.umowa_niemiecka} onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="umowa_niemiecka">{isTranslated ? 'Deutscher Vertrag' : 'Umowa niemiecka'}</label>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="status" name="status" checked={formData.status} onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="status">{isTranslated ? 'Aktuell?' : 'Czy aktualne?'}</label>
                                </div>
                                <button type="submit" className="btn btn-outline-primary">{isTranslated ? 'Einreichen' : 'Zatwierdź'}</button>
                            </form>
                        </div>
                    )}







                    {/* wersja niemiecka */}
                    {selectedLanguage === 'DE' && (
                        <div className='vh30 container'>
                            <button className='btn btn-link purple' onClick={handleRefresh}>Züruck</button>
                            <h2>Erstellen Sie ein deutsches Stellenangebot</h2>
                            <form onSubmit={handleSubmit}>
                                <div className='row d-flex justify-content-center py-4'>
                                    <div className='col-lg-4 ps-4 py-0 pe-0 d-flex bg-light justify-content-end'>
                                        <div className='d-block position-relative'
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleFileInputClick}
                                            style={{ cursor: 'pointer' }}>
                                            <img src='logo/logo.png' className='w-100 bg-white' />
                                            {isHovered && (
                                                <div className='lebens-overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center' style={{ top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.5)', color: 'white' }}>
                                                    <h2>Ändern</h2>
                                                </div>
                                            )}
                                        </div>
                                        <input name='img' type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="name_de" className="form-label">Name der Anzeige</label>
                                    <input type="text" className="form-control" id="name_de" name="name_de" value={formData.name_de} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description_de" className="form-label">Beschreibung der Anzeige</label>
                                    <textarea className="form-control" id="description_de" name="description_de" value={formData.description_de} onChange={handleChange} required></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="wynagrodzenie_de" className="form-label">Vergütung</label>
                                    <input type="text" className="form-control" id="wynagrodzenie_de" name="wynagrodzenie_de" value={formData.wynagrodzenie_de} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="land" className="form-label">Bundesland</label>
                                    <select className="form-control" id="land" name="land_id" value={formData.land_id} onChange={handleLandChange} required>
                                        <option value="">Wählen Sie ein Land</option>
                                        {lands.map((land) => (
                                            <option key={land.id} value={land.id}>{land.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="city" className="form-label">Stadt</label>
                                    <select className="form-control" id="city" name="city_id" value={formData.city_id} onChange={handleCityChange} required>
                                        <option value="">Wählen Sie eine Stadt</option>
                                        {cities.map((city) => (
                                            <option key={city.id} value={city.id}>{city.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
    <label htmlFor="category" className="form-label">Kategorie</label>
    <select className="form-control" id="category_de" name="category_id" value={formData.category_id} onChange={handleCategoryChange} required>
        <option value="">{isTranslated ? 'Wählen Sie eine Kategorie' : 'Wybierz kategorie'}</option>
        {jobsCategories.map((category) => (
            <option key={category.id} value={category.id}>{category.name_de}</option>
        ))}
    </select>
</div>
<div className="mb-3">
    <label htmlFor="job" className="form-label">Arbeitsstelle</label>
    <select className="form-control" id="job_de" name="job_id" value={formData.job_id} onChange={handleChange} required>
        <option value="">Wählen Sie eine position</option>
        {jobs.map((job) => (
            <option key={job.id} value={job.id}>{job.name_de}</option>
        ))}
    </select>
</div>
<div className="mb-3">
                                    <label htmlFor="time" className="form-label">Arbeitszeit</label>
                                    <select className="form-control" id="time" name="time_id" value={formData.time_id} onChange={handleChange} required>
                                        <option value="">Wählen Sie eine Arbeitzeitregelung</option>
                                        {time.map((time) => (
                                            <option key={time.id} value={time.id}>{time.name}</option>
                                        ))}
                                    </select>
                                </div>
   
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="accomodation" name="accomodation" checked={formData.accomodation} onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="accomodation">Unterkunft vorhanden</label>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="umowa_niemiecka" name="umowa_niemiecka" checked={formData.umowa_niemiecka} onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="umowa_niemiecka">Deutscher Vertrag</label>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="status" name="status" checked={formData.status} onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="status">Aktuell</label>
                                </div>
                                <button type="submit" className="btn btn-outline-primary">Einreichen</button>
                            </form>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default CreateWork;
