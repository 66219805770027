import { useState } from "react";
import { useAuth } from "../config/AuthContext";
import { useTranslate } from "../config/translateContext";
import { useNavigate } from "react-router-dom";
import './Login.css';

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const { login, loading } = useAuth(); 
  const { isTranslated } = useTranslate();
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear any previous error message
    try {
      const success = await login(username, password);
      if (success) {
        navigate('/'); // Redirect to the homepage
      } else {
        setErrorMessage(isTranslated ? 'Anmeldung fehlgeschlagen' : 'Logowanie nie powiodło się');
      }
    } catch (error) {
      console.error('Error during authentication', error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.detail || (isTranslated ? 'Ein Fehler ist aufgetreten' : 'Wystąpił błąd'));
      } else {
        setErrorMessage(isTranslated ? 'Ein Fehler ist aufgetreten' : 'Wystąpił błąd');
      }
    }
  }



    const handleRedirect = () => {
    navigate('/pass-reset'); // Przekierowanie do strony resetowania hasła
  };

  return (
    <div className="Auth-form-container login-main">
      <div className="background">
        <div className="shape circle"></div>
        <div className="shape square"></div>
        <div className="shape triangle"></div>
      </div>
      <form className="Auth-form" onSubmit={submit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title my-5">{isTranslated ? 'Einloggen' : 'Zaloguj się'}</h3>

          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

          <div className="form-group mt-3">
            <label className="my-2">{isTranslated ? 'Benutzername' : 'Nazwa użytkownika'}</label>
            <input
              className="form-control mt-1 px-5"
              placeholder="użytkownik/benutzername"
              name='username'
              type='text'
              value={username}
              required
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>{isTranslated ? 'Kennwort' : 'Hasło'}</label>
            <input
              name='password'
              type="password"
              className="form-control mt-1 px-5"
              placeholder="hasło/kennwort"
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className="d-flex w-100 justify-content-center">
            <div className="gap-2 mt-3">
              <button type="submit" className="btn btn-primary" disabled={loading}>
                {isTranslated ? 'Einloggen' : 'Zaloguj się'}
              </button>

            </div>
          </div>
        </div>
    <button className='btn btn-link text-uppercase mt-2 purple' onClick={handleRedirect}>
   {isTranslated ? 'Passwort zurücksetzen' : 'Zresetuj hasło'}
    </button>
      </form>

    </div>
  );
};

export default Login;
