import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../config/AuthContext';
import axiosInstance from '../config/axiosConfig';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { useTranslate } from '../config/translateContext';




const Contacts = () => {
  const { profileId, isAuthenticated, user } = useContext(AuthContext);
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const [roomData, setRoomData] = useState([]); // Store room data (initially empty array)
  const [loading, setLoading] = useState(true); // Manage loading state
  const [selectedRoomId, setSelectedRoomId] = useState(null); // Store the selected room ID
  const [messages, setMessages] = useState([]); // Store messages
  const [inputMessage, setInputMessage] = useState(''); // Store user input message
  const ws = useRef(null); // Reference to WebSocket
  const chatWindowRef = useRef(null);
  const { slug } = useParams(); // Pobierz slug z URL
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();
  const [showContacts, setShowContacts] = useState(false);
  const [showConversations, setShowConversations] = useState(false);
  const { isTranslated, toggleTranslate } = useTranslate();
  const handleShowContacts = () => setShowContacts(true);
  const handleCloseContacts = () => setShowContacts(false);
  const handleShowConversations = () => setShowConversations(true);
  const handleCloseConversations = () => setShowConversations(false);

  const [roomExists, setRoomExists] = useState(null);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const checkSituation = async (profileId) => {
    if (!isAuthenticated || !profileId) {
      console.error('User is not authenticated or profileId is not available');
      setLoading(false); // make sure to stop the loading spinner
      return;
    }
    try {
      // Wykonaj żądanie do endpointu zwracającego dane pokoju i listę kontaktów
      const response = await axiosInstance.get('/chat/check-room/', {
        params: {
          profileId: profileId,
        },
      });

      console.log('Odpowiedź z backendu:', response.data);

      const {
        room_exists,
        my_rooms,
        contact_list_true,
        contact_list,
      } = response.data;

      // Aktualizuj stan pokoju
      setRoomData(room_exists ? my_rooms : []);

      // Aktualizuj stan kontaktów
      if (contact_list_true) {
        setContacts(contact_list);
        console.log('Contact URLs:', contact_list.map(contact => contact.url));
      } else {
        setContacts([]); // Upewnij się, że stan jest pusty, jeśli nie ma kontaktów
        console.error('Brak dostępnych kontaktów');
      }
    } catch (error) {
      console.error('Error checking room situation or fetching contacts:', error);
    } finally {
      setLoading(false); // Stop loading when the request is completed
    }
  };

  // Function to handle room selection and WebSocket connection
  const connectWebSocket = (roomId) => {
    if (ws.current) {
      console.log('Closing existing WebSocket connection...');
      ws.current.close();
    }

    // Create a new WebSocket connection
    const wsBaseURL = 'obrotni.info';
    const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    const wsURL = `${wsProtocol}${wsBaseURL}/ws/private/${roomId}/`;

    const newWs = new WebSocket(wsURL);

    newWs.onopen = () => {
      console.log('WebSocket connected to room:', roomId);
      ws.current = newWs;

      // Join the room
      newWs.send(
        JSON.stringify({
          type: 'join_room',
          room_id: roomId,
        })
      );
    };

    newWs.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received message from server check:', data);

      if (data.type === 'room_joined') {
        setMessages(data.messages);
      } else if (data.message) {
        setMessages((prevMessages) => [...prevMessages, data.message]);
      }
    };

    newWs.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    newWs.onclose = () => {
      console.log('WebSocket disconnected');
    };

    ws.current = newWs; // Set WebSocket reference to the new instance
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleRoomSelect = (roomId) => {
    console.log('handle room selected clicked');
    setSelectedRoomId(roomId);
    setMessages([]);
    handleCloseConversations();
  };

  useEffect(() => {
    if (selectedRoomId) {
      connectWebSocket(selectedRoomId);
    }
  }, [selectedRoomId]);

  useEffect(() => {
    if (!selectedRoomId && roomData && roomData.length > 0) {
      setSelectedRoomId(roomData[0].id); // Set the first room as default
    }
  }, [roomData, selectedRoomId]);

 const handleSubmit = (e) => {
  e.preventDefault();
  console.log('Message submitted:', inputMessage);

  if (!selectedRoomId) {
    console.error('No room selected');
    return;
  }

  if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
    console.error('WebSocket is not open or message is empty');
    return;
  }

  if (!inputMessage.trim()) {
    console.error('Message is empty');
    return;
  }

  // Send the message if everything is fine
  ws.current.send(
    JSON.stringify({
      type: 'message',
      message: inputMessage,
      sender_id: profileId,
    })
  );
  setInputMessage(''); // Clear the input field
};


  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
        const token = localStorage.getItem('access_token');
        const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        const wsBaseURL = 'obrotni.info';
        const wsURL = `${wsProtocol}${wsBaseURL}/ws/notifications/?token=${encodeURIComponent(token)}`;

        console.log("WebSocket notyfikacje URL:", wsURL);

        const ws = new WebSocket(wsURL);

        ws.onopen = () => {
            console.log('WebSocket connection opened');
        };

        ws.onmessage = (event) => {
            console.log("WebSocket message received:", event.data);
            try {
                const data = JSON.parse(event.data);
                console.log("Parsed WebSocket message:", data);  // Loguj parsowane dane

                if (data.new_message) {
                    const { room_id, message } = data;
                    setRoomData((prevRoomData) =>
                        prevRoomData.map(room =>
                            room.id === room_id ? { ...room, hasNewMessage: true } : room
                        )
                    );
      console.log(`New message received in room ${room_id}`);
    } else {
      console.error('room_id is undefined');
                }
            } catch (error) {
                console.error("Error parsing WebSocket message:", error);
            }
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onclose = () => {
            console.log("WebSocket connection closed");
        };

        return () => {
            ws.close();
            console.log("WebSocket connection closed on component unmount");
        };
    }
}, [isAuthenticated]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated && user && user.profile_id) {
        await checkSituation(user.profile_id);
      }
    };

    fetchData();
  }, [isAuthenticated, user]);

  const isSenderCurrentUser = (senderId) => {
    return String(senderId) === String(profileId);
  };

  const handleStartConversation = async (contactId) => {
    try {
      const response = await axiosInstance.post('/chat/room/create/', {
        sender: profileId,
        receiver: contactId,
      });

      const { roomAlreadyExists, room_id, room_data } = response.data;

      if (roomAlreadyExists) {
        console.log('pokój istnieje:', room_data);
        setRoomExists(true);

        const roomExistsInState = roomData.find(room => room.id === room_id);
        if (!roomExistsInState && room_data) {
          setRoomData((prevRoomData) => [...prevRoomData, room_data]);
        }
        setSelectedRoomId(room_id);
      } else {
        console.log('New room created:', room_data);

        if (room_data && room_data.first && room_data.second) {
          setRoomData((prevRoomData) => [...prevRoomData, room_data]);
        }
        setSelectedRoomId(room_id);
      }
      handleCloseContacts();
    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  };

  const handleTranslateMessage = async (messageId) => {
    try {
      const response = await axiosInstance.post('/translate-private/', {
        message_id: messageId,
      });

      const translatedMessage = response.data.translated_private;
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === messageId ? { ...message, content_de: translatedMessage } : message
        )
      );
    } catch (error) {
      console.error("Error translating message:", error);
    }
  };

  if (loading) {
    return (
      <div className='w-100 d-flex justify-content-center h-100 ontop position-fixed t-0 cloude'>
        <img src='/img/logo.png' className='rotating-object align-self-center loader' alt="Loader" />
      </div>
    );
  }

  return (
    <div className='container chat'>
      <h1>Pogadajmy!</h1>
      <div className='d-flex gap-3 my-5 justify-content-end'>
        <Button variant="primary" onClick={handleShowContacts} className='v9'>
          Kontakty
        </Button>
        <Offcanvas show={showContacts} onHide={handleCloseContacts}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Twoje kontakty</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {contacts.length > 0 ? (
              <ul className='list-unstyled'>
                {contacts.map((contact) => (
                  <li key={contact.id}>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>{contact.persone}</Accordion.Header>
                        <Accordion.Body>
                          <ul className='list-unstyled'>
                            <li>{contact.name} {contact.surname}</li>
                            <li>{contact.job?.name ? contact.job.name : 'nie wybrano profesji'}</li>
                            <li>{contact.land?.name ? contact.land.name : 'nie wybrano landu'}</li>
                            <li>{contact.city?.name ? contact.city.name : 'nie wybrano miasta'}</li>
                            <a href={contact.obrotni_url}>Profil</a>
                            {roomExists ? (
                            <p className='text-success fw-bold'>
  {isTranslated ? 'Konversation im Gange, du findest sie in den Konversationen' : 'rozmowa w toku, znajdziesz ją w konwersacjach'}
</p>

                            ) : (
                              <button className='btn' onClick={() => handleStartConversation(contact.id)}>
                                <i className="bi bi-chat-dots"></i> Rozpocznij rozmowę
                              </button>
                            )}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Brak dostępnych kontaktów.</p>
            )}
          </Offcanvas.Body>
        </Offcanvas>

        <Button variant="primary" onClick={handleShowConversations} className='v9'>
          Konwersacje
        </Button>

       <Offcanvas show={showConversations} onHide={handleCloseConversations}>
  <Offcanvas.Header closeButton>
    <Offcanvas.Title>Twoje konwersacje</Offcanvas.Title>
  </Offcanvas.Header>
  <Offcanvas.Body>
    <ul className='list-unstyled ms-3'>
      {roomData && roomData.length > 0 ? (
        roomData.map((room, index) => {
          const isFirstUser = String(room.first.id) === String(profileId);
          const otherParticipant = isFirstUser ? room.second : room.first;

          return (
            <li key={index}>
              <button
                onClick={() => handleRoomSelect(room.id)}
                className={`btn neumorphism my-1 ${room.hasNewMessage ? 'new-message' : ''}`}  // Dodano klasę 'new-message'
              >
                {otherParticipant && otherParticipant.user && otherParticipant.user.username
                  ? otherParticipant.user.username
                  : 'Unknown'}
              </button>
            </li>
          );
        })
      ) : (
        <p>Brak dostępnych konwersacji.</p>
      )}
    </ul>
  </Offcanvas.Body>
</Offcanvas>

      </div>

      <div className='row'>
        <div className='col-md-8 offset-md-2 col-12 col-sm-12 order-sm-3 order-lg-2'>
          {messages.length > 0 && (
            <div className="chat-header p-2 mb-2">
<h4 className="text-start chat-header neumorphism pb-3">
  {isTranslated ? (
    <>
      {/* Tłumaczenie */}
      Rozmowa z: {messages[0].sender?.user?.username !== profileId && 'Translated Username'}
    </>
  ) : (
    <>
      {/* Oryginalna nazwa użytkownika */}
      Rozmowa z: {messages[0].sender?.user?.username !== profileId && messages[0].sender?.user?.username}
    </>
  )}
</h4>

            </div>
          )}

<div className='row chat-window neumorphism mb-2' ref={chatWindowRef}>
  {messages.length > 0 ? (
    messages.map((message, index) => {
      const senderId = message.sender?.id || message.sender_id;
      console.log('Sender object:', message.sender);
      const isCurrentUser = isSenderCurrentUser(senderId);
      const profileImgUrl = message.sender?.profilimg ? `${mediaUrl}${message.sender.profilimg}` : '/default-profile-img.jpg';

      return (
        <div key={index} className='row mb-2 p-3 d-flex align-items-center'>

          {/* Kolumna z obrazkiem w zależności od tego, czy wiadomość jest od obecnego użytkownika */}
          <div className={`col-3 text-center ${isCurrentUser ? 'order-2' : 'order-1'}`}>
            <img
              src={profileImgUrl}
              alt={message.sender?.user?.username || 'Profil'}
              style={{ width: '40px', height: '40px', borderRadius: '50%' }}
            />   <p>{message.sender?.user?.username}</p>
          </div>

          {/* Kolumna z wiadomością */}
          <div className={`col-9 text-start neumorphism ${isCurrentUser ? 'order-1' : 'order-2'}`}>

            <p className='text-end pb-3 fw-italic'><small className='fw-italic'>({formatDate(message.created_at)})</small></p>
            <div>
         <p>     {message.content}</p>
              {message.content_de && (
                <div className='translated-message'>
                  <span className='purple fst-italic'>{message.content_de}</span>
                </div>
              )}
            </div>
            <button className='btn' onClick={() => handleTranslateMessage(message.id)}>
              <i className="bi bi-translate purple"></i>
            </button>
          </div>

        </div>
      );
    })
  ) : (
    <p>Jeszcze nikt nie zaczął rozmowy!</p>
  )}
</div>


          <div className='row private-chat-form neumorphism'>
            <form onSubmit={handleSubmit}>
              <div className="input-group flex-nowrap">
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="..."
                  value={inputMessage}
                  onChange={handleInputChange}
                ></textarea>
                <button type="submit" className="btn btn-outline-dark mt-2 mx-3 px-3">
                  <i className="bi bi-send purple"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
