import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../config/axiosConfig';
import { Link } from 'react-router-dom';
import { fadeAnimation } from '../animations/gsap';
import NewsletterForm from './newsletter';
import { useTranslate } from '../config/translateContext';
import { gsap } from 'gsap';
import { useLoading } from '../config/loadingContext';

function Artykul() {
  const [artykul, setArtykul] = useState(null);
  const { slug } = useParams();
  const myFade = useRef(null);
  const [translatedTitle, setTranslatedTitle] = useState("");
  const [translatedIntro, setTranslatedIntro] = useState("");
  const [translatedAkapity, setTranslatedAkapity] = useState([]);
  const { isTranslated } = useTranslate();
  const { setIsLoading } = useLoading();
  const [error, setError] = useState('');
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const [videos, setVideos] = useState([]);
  const [akapity, setAkapity] = useState([]);
  const apiUrl = `/infoteka/${slug}/`;
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(apiUrl);

      // Logowanie całej odpowiedzi
      console.log("Fetched data:", response.data);

      // Logowanie poszczególnych elementów
      console.log("Fetched post:", response.data.post);
      console.log("Fetched akapity:", response.data.akapity);
      console.log("Fetched videos:", response.data.videos);

      // Ustawienie danych artykułu, akapitów i wideo
      setArtykul(response.data.post);
      setAkapity(response.data.akapity);
      setVideos(response.data.videos);

      // Zamiast inicjowania `translatedAkapity`, po prostu ustaw akapity
      setTranslatedAkapity(response.data.akapity);

    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError("Failed to load data.");
    } finally {
      setIsLoading(false);
    }
  };

  fetchData();
}, [slug, apiUrl, setIsLoading]);



  useEffect(() => {
    if (myFade.current) {
      fadeAnimation(myFade.current);
    }
  }, [artykul]);


const translateTexts = async (text, type, index = null) => {
  const data = { texts: {} };

  if (type === 'title') {
    data.texts.title = text;
  } else if (type === 'intro') {
    data.texts.intro = text;
  } else if (type === 'content') {
    data.texts.akapit = text;  // Zmienione na `akapit`
  }

  try {
    setIsLoading(true);
    const response = await axiosInstance.post('/translate/', data);
    console.log('Response data:', response.data);

    const translatedText = response.data.translated_akapit || response.data.translated_title || response.data.translated_intro;

    if (type === 'title') {
      setTranslatedTitle(translatedText);
    } else if (type === 'intro') {
      setTranslatedIntro(translatedText);
    } else if (type === 'content' && index !== null) {
      // Aktualizacja przetłumaczonego akapitu na podstawie indeksu
      setTranslatedAkapity(prevAkapity => prevAkapity.map((akapit, idx) =>
        idx === index ? { ...akapit, translatedContent: translatedText } : akapit
      ));
    }
  } catch (error) {
    console.error("Błąd podczas tłumaczenia tekstu:", error);
  } finally {
    setIsLoading(false);
  }
};

  
  

  useEffect(() => {
    const elementsToAnimate = gsap.utils.toArray('.animatable');
    if (elementsToAnimate.length > 0) {
      gsap.fromTo(elementsToAnimate, { opacity: 0, x: -10 }, { opacity: 1, x: 0, duration: 0.5, stagger: 0.2 });
    }
  }, [isTranslated]);

  if (!artykul) return null;

  return (
    <>
      <div className='container fading vh10'>
        <div className='row my-5'>
          <p><Link className='d-flex align-items-center' to="/infoteka"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16">
            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/></svg>
            {isTranslated ? 'Infotheke' : 'Infoteka'}
          </Link></p>
        </div>
        <div className='row my-5'>
          <div className='d-flex gap-3'>
            <h2 className='my-5'>{translatedTitle || artykul.title}</h2>
            <button className='btn gradient-text' onClick={() => translateTexts(artykul.title, 'title')}> <i className="bi bi-translate"></i></button>
          </div>
          {artykul?.cykl?.name && (
  <h3 className='infoteka-color animatable'>
    {isTranslated ? `aus der Serie ${artykul.cykl.name_de}` : `z cyklu ${artykul.cykl.name}`}
  </h3>
)}

          <p className='animatable'>{isTranslated ? `Veröffentlichungsdatum: ${artykul.date}` : `Data publikacji: ${artykul.date}`}</p>
          <p className='purple animatable'>{isTranslated ? `Ressort: ${artykul.presscategory.name_de}` : `Dział: ${artykul.presscategory.name}`}</p>
        </div>
        <div className='row my-5 d-flex justify-content-center sky p-2'>
          {artykul.img && (
            <img loading='lazy' src={artykul.img} alt={artykul.title} className='img-fluid w-50' />
          )}
        </div>
        <div>
          {videos && videos.length > 0 && videos.map((video, index) => (
            <div key={index} className="video-container d-flex justify-content-center">
              <div dangerouslySetInnerHTML={{ __html: video.link }} />
            </div>
          ))}
        </div>
        <div className='cloud row'>
          <button className='btn gradient-text' onClick={() => translateTexts(artykul.intro, 'intro')}>
             <i className="bi bi-translate"></i>
          </button>
          <div dangerouslySetInnerHTML={{ __html: translatedIntro || artykul.intro }} className='blue fs-5' />
        </div>
{akapity && akapity.length > 0 ? (
  akapity.map((akapit, index) => (
    <div key={index} className='row my-5'>
      {akapit.img ? (
        <>
          <div className='col-md-6 col-lg-6 col-12 col-sm-12'>
            <button className='btn gradient-text' onClick={() => translateTexts(akapit.content, 'content', index)}>
              <i className="bi bi-translate"></i>
            </button>
            <p className='blue'>{translatedAkapity[index]?.translatedContent || akapit.content}</p>
          </div>
          <div className='col-md-6 col-lg-6 col-12 col-sm-12 d-flex justify-content-center'>
            <img loading='lazy' src={akapit.img} alt="" className='img-fluid' />
          </div>
        </>
      ) : (
        <div className='col-md-12'>
          <button className='btn gradient-text' onClick={() => translateTexts(akapit.content, 'content', index)}>
            <i className="bi bi-translate"></i>
          </button>
          <div dangerouslySetInnerHTML={{ __html: translatedAkapity[index]?.translatedContent || akapit.content }} className='blue lato-regular' />
        </div>
      )}
    </div>
  ))
) : (
  <div dangerouslySetInnerHTML={{ __html: 'Brak dostępnych akapitów do wyświetlenia.' }} className='blue' />
)}


{artykul.banner && (
  <div className='row my-5'>

    <img src={artykul.banner} alt="Banner" className="w-100" />

  </div>
)}    

        <div className='row my-5'>
          <div className='my-5 col-md-3'>
            <p className='blue'>Autor: {artykul.autor.name} {artykul.autor.surname}</p>
            {artykul.autor.profilimg && <img loading='lazy' src={artykul.autor.profilimg} alt="Profil autora" className='img-fluid' />}
          </div>
          {artykul.edytor && artykul.edytor.name && (
            <div className='purple m-5 col-md-3'>
              <p>Edytor: {artykul.edytor.name} {artykul.edytor.surname}</p>
              {artykul.edytor.profilimg && <img loading='lazy' src={artykul.edytor.profilimg} alt="Profil edytora" className='img-fluid' />}
            </div>
          )}
        </div>

      </div>
    </>
  );
}

export default Artykul;
